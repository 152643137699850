import '../App/App.css';

function Header() {
return(
    <header className="App-header">
        <h1>
            Moritz Christl
        </h1>
        <p>
            Student · Fullstack-Developer · Rollercoaster-Enthusiast
        </p>
    </header>)
}

export default Header;
