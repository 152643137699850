import './Rollercoasters.css';

function RandomAttraction(){
    return(
        <div  className={"PrivacyPolicy"}>
        <h1>Datenschutzerklärung für die App "Random Attraction"</h1>
        <div>
            <p>
                Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) <br/>
                im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres Angebotes und der mit dieser verbundenen App (nachfolgend gemeinsam bezeichnet als „Angebot“). <br/>
                Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).<br/>
            </p>
            <h2> Verantwortlicher </h2>
            <p>
                Moritz Christl Hüllbeen 21 21079 Hamburg DE <br/>
                E-Mailadresse: apps (at) moritzchristl.de
            </p>
            <h2>Arten der verarbeiteten Daten</h2>
            <li>Texteingaben</li>
            <h2>Kategorien betroffener Personen</h2>
            <p>
                Besucher und Nutzer des Angebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
            </p>
            <h2>Zweck der Verarbeitung</h2>
            <li>Zurverfügungstellung des Angebotes, seiner Funktionen und Inhalte.</li>
            <h2>Art der verarbeiteten Daten</h2>
            <p>
                Beim Nutzen der Anwendung erhält der Nutzer die Möglichkeit Texteingaben nach eigenem Ermessen zu tätigen. <br/>
                Diese Texteingaben werden lokal in einer Datenbank auf dem Gerät des Nutzers gespeichert, um die Funktionalität der App zu gewährleisten. <br/>
                Die Daten werden nicht an externe Server weitergleitet oder in anderer Art verarbeitet. <br/>
            </p>
            <h2>Widerrufsrecht</h2>
            <p>Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft zu widerrufen. </p>
            <h2>Widerspruchsrecht <br/></h2>
            <h3>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. <br/>

            </h3>
            <h2>Löschung von Daten</h2>
            <p>Dem Nutzer steht es frei jederzeit die selbst eingegeben Daten in der App zu löschen. <br/>
                Es werden keine weiteren Daten erhoben, die nach gesetzlichen Vorgaben gelöscht werden müssen.</p>
            <h2>Änderungen und Aktualisierungen der Datenschutzerklärung</h2>
            <p>Wir bitten Sie sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. <br/>
                Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. <br/>
                Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
        </div>
        </div>

)
}

export default RandomAttraction;
