import './Rollercoasters.css';
import Header from "../header/Header";

function Rollercoasters(){
    return(
        <div className={"Rollercoaster"}>
	    <Header />
            <p>248 Rollercoasters</p>
            <p>38 Theme Parks</p>
            <p> Favourite Coasters:  ­
                <a className={"link"} href={"https://www.youtube.com/watch?v=a_UoPkQ9Y4c"}><span className={"link-text"}>Untamed</span></a>, ­
                <a className={"link"} href={"https://www.youtube.com/watch?v=0DQzlhYgsaE"}><span className={"link-text"}>X2</span></a>, ­
                <a className={"link"} href={"https://www.youtube.com/watch?v=Lel7khhrs4Q"}><span className={"link-text"}>Tatsu</span></a>
            </p>
            <p> Favourite Parks:  ­
                <a className={"link"} href={"https://www.phantasialand.de/de/"}>Phantasialand, </a>
                <a className={"link"} href={"https://www.europapark.de/de"}>Europapark, </a>
                <a className={"link"} href={"https://www.sixflags.com/magicmountain"}>Six Flags Magic Mountain</a>
            </p>
            <p> Home Parks: Heide Park, Hansa Park</p>
        </div>
    )
}

export default Rollercoasters;
