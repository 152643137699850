import './App.css';
import Header from '../header/Header.js';
import Rollercoasters from "../Rollercoasters/Rollercoasters";
import RandomAttraction from "../Rollercoasters/RandomAttraction";
import {React} from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Outlet} from "react-router";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Header />}/>
          <Route exact path='/coaster' element={<Rollercoasters/>}/>
	  <Route exact path='/randomAttraction/privacypolicy' element={<RandomAttraction/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
